.filter-menu {
	width: 280px;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.filter-menu__form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.filter-menu__buttons {
	margin: 1rem 0 0;
	display: flex;
	gap: 1rem;
}