.shift-entry {
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.shift-entry--invisible {
  display: none;
}

.shift-entry:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.shift-entry__avatar {
  width: 30px !important;
  height: 30px !important;
  font-size: 0.8rem !important;
}

.shift-entry__store {
  margin: 0;
  text-transform: uppercase;
  font-size: 0.8rem;
}