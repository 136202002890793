.calendar {
  font-family: Arial, sans-serif;
  margin: 2rem auto 1rem;
}

.calendar__header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #535353;
}

.calendar__month {
  margin: 0;
  text-transform: uppercase;
  color: #FFFFFF;
}

.calendar__btn {
  color: #FFFFFF;
}

.calendar__btn.calendar__btn--active {
  color: #38bdf8;
}

.calendar__weekdays {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: #f0f0f0;
  padding: 10px 0;
  font-weight: bold;
}

.calendar__weekday {
  text-align: center;
}

.calendar__days {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  background-color: #ddd;
}

  