.day {
  min-height: 80px;
  padding: 0.5rem;
  text-align: center;
  background-color: #ffffff;
}

.empty-day {
  background-color: #dddddd;
}

.day__date {
  font-weight: bold;
  margin: 0rem;
  padding: 0.5rem;
  text-align: right;
}

.day.day--completed .day__date {
  background-color: #9bb6ff;
}