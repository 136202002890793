.store {
	margin: 0;
	padding: 0.5rem;
	display: flex;
	align-items: center;
}

.store__name {
	margin: 0;
	font-weight: 400;
}

.store__form {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.store__form-title {
	text-align: center;
}

.store__form-buttons {
	display: flex;
	gap: 1rem;
}