.shift-planner {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.shift-planner__header {
  display: flex;
  justify-content: space-between;
}

.shift-planner__title {
  margin: 0 0 1rem;
}

.controls {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.controls__month-input {
  width: 100px;
  max-height: 200px;
}

.controls__year-input {
  width: 100px;
}


.control-panel {
  margin: 1rem 0;
}

.control-panel__header {
  display: flex;
  gap: 1rem;
}

.control-panel__title {
  margin: 0.5rem 0;
  text-transform: uppercase;
}

.control-panel__container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
