.employee {
	margin: 0;
	padding: 0.5rem;
	display: flex;
	align-items: center;
}

.employee__name {
	margin: 0;
	font-weight: 400;
}

.employee__avatar {
	margin: 0 1rem 0 0;
  width: 30px !important;
  height: 30px !important;
  font-size: 0.8rem !important;
}

.employee__form {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.employee__form-title {
	text-align: center;
}

.employee__form-buttons {
	display: flex;
	gap: 1rem;
}
